import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function tripCostLimit(max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if (value && value > max) {
      control.markAsTouched();
      return { tripCostLimit: { message: 'Trip cost exceeds max limit' } };
    }
    return null;
  };
}
