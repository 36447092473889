import { FieldType } from '@shared/core/enums/field-type.enum';
import { FormBuilderActions } from '@pages/home/enums/form-builder-actions.enum';
import { DateTime } from 'luxon';
import { Validators } from '@angular/forms';
import { tripCostLimit } from '@shared/core/validators/trip-cost-limit.validator';
import { FormConfig } from '../interfaces/config.interface';

export const TRAVELER_CONFIG: FormConfig = {
  title: 'Traveler',
  modalHeader: 'Traveler Info',
  fields: [
    {
      name: 'birthdate',
      label: 'Date of birth',
      placeholder: '01/01/2000',
      value: '',
      type: FieldType.MASKED_TYPE,
      validation: [Validators.required],
      maskConfig: {
        mask: 'M0/d0/0000',
        showMaskTyped: true,
        validation: true,
        leadZeroDateTime: true,
        placeholderCharacter: 'mm/dd/yyyy',
        dropSpecialCharacters: false,
      },
    },
    {
      name: 'cost',
      label: '$ Full Trip Cost per person',
      placeholder: '$ Full Trip Cost per person',
      value: '',
      type: FieldType.MASKED_TYPE,
      validation: [Validators.required, tripCostLimit(25000)],
      maskConfig: {
        mask: 'separator.2',
        showMaskTyped: false,
        validation: false,
        thousandSeparator: ',',
        decimalMarker: '.',
        dropSpecialCharacters: true,
      },
    },
  ],
  actions: {
    addField: {
      type: FormBuilderActions.ADD_FIELD,
      text: 'Add Traveler',
      icon: 'assets/icons/traveler.svg',
    },
    deleteField: {
      type: FormBuilderActions.DELETE_FIELD,
      icon: 'assets/icons/trash.svg',
    },
    nextStep: {
      type: FormBuilderActions.NEXT_STEP,
      text: 'Next',
    },
  },
  meta: {
    allowAddControls: true,
    isArray: true,
  },
  step: 1,
};

export const TRAVEL_DATES: FormConfig = {
  modalHeader: 'Select dates',
  fields: [
    {
      name: 'dateRange',
      label: 'Date Range',
      placeholder: 'Add dates',
      value: '',
      type: FieldType.DATE_RANGE,
      validation: [Validators.required],
      minDate: DateTime.local().plus({ days: 1 }).toJSDate(),
    },
  ],
  actions: {
    nextStep: {
      type: FormBuilderActions.NEXT_STEP,
      text: 'Next',
    },
  },
  step: 2,
};

export const RESIDENCE_STATE: FormConfig = {
  modalHeader: 'State of Residence',
  fields: [
    {
      name: 'state',
      label: 'Add state',
      placeholder: 'Enter state',
      value: '',
      type: FieldType.SELECT,
      validation: [Validators.required],
    },
  ],
  actions: {
    nextStep: {
      type: FormBuilderActions.NEXT_STEP,
      text: 'Next',
    },
  },
  step: 3,
};

export const FIRST_PAYMENT_DATE: FormConfig = {
  modalHeader: 'Select date',
  fields: [
    {
      name: 'payment',
      label: 'First Deposit Amount',
      placeholder: 'First Deposit Amount',
      value: '',
      type: FieldType.MASKED_TYPE,
      validation: [Validators.required, Validators.min(1)],
      maskConfig: {
        mask: 'separator.2',
        showMaskTyped: false,
        validation: false,
        thousandSeparator: ',',
        decimalMarker: '.',
      },
      tooltip: 'Please enter the amount you have paid as a deposit for your trip. This amount should be calculated as an amount for all travelers not per person. If you have not yet booked, you can enter the deposit amount to be paid.',
    },
    {
      name: 'date',
      label: 'Add date',
      placeholder: 'Add date',
      value: '',
      type: FieldType.DATE_SINGLE,
      validation: [Validators.required],
      maxDate: DateTime.now().toJSDate(),
      tooltip: 'Please enter the date you made your first booking for this trip. This could be when you booked your flight or hotel, or any trip activity. If you have not yet booked, please use today’s date.',
    },
  ],
  actions: {
    nextStep: {
      type: FormBuilderActions.NEXT_STEP,
      text: 'Next',
    },
  },
  groupLevelValidators: [Validators.required],
  step: 4,
};
