import {
  FIRST_PAYMENT_DATE,
  RESIDENCE_STATE,
  TRAVELER_CONFIG,
  TRAVEL_DATES,
} from '../configs/travel-protection-form.config';
import { FormStep } from '../enums/form-step.enum';
import { TravelProtectionForm } from '../enums/travel-protection-form.enum';
import { FormTrigger } from '../interfaces/input-trigger.interface';

export const formTriggers: FormTrigger[] = [
  {
    type: TravelProtectionForm.TRAVELER_INFO,
    title: 'Traveler info',
    subtitle: 'Add info',
    step: FormStep.One,
    formConfig: TRAVELER_CONFIG,
    tooltip: 'Please enter the date of birth and trip cost per person of each traveler you would like covered by this policy. Travelers must have the same travel dates, itinerary, and same residence address to be insured on one policy.',
    tooltipConfig: {
      tooltipPosition: 'right',
    },
  },
  {
    type: TravelProtectionForm.TRAVEL_DATES,
    title: 'Dates of Travel',
    subtitle: 'Add dates',
    step: FormStep.Two,
    formConfig: TRAVEL_DATES,
    tooltip: 'Please enter the date your trip begins and ends. This should be the day you leave your home and the day you return home.',
    tooltipConfig: {
      tooltipPosition: 'right',
    },
  },
  {
    type: TravelProtectionForm.RESIDENCE_STATE,
    title: 'State of residence',
    subtitle: 'Add state',
    step: FormStep.Three,
    filter: '',
    formConfig: RESIDENCE_STATE,
    initSubtitle: 'Add state',
    tooltip: 'Please enter your primary state of residence. This should be where you reside at the start of the trip.',
    tooltipConfig: {
      tooltipPosition: 'right',
    },
  },
  {
    type: TravelProtectionForm.FIRST_PAYMENT_DATE,
    title: 'First Trip Payment Info',
    subtitle: 'Add date',
    step: FormStep.Four,
    formConfig: FIRST_PAYMENT_DATE,
  },
];
