export enum FieldType {
  NUMBER = 'number',
  DATE_RANGE = 'range',
  DATE_SINGLE = 'single',
  DATE_MASKED = 'date',
  EMAIL = 'email',
  PASSWORD = 'password',
  SELECT = 'select',
  TEXT = 'text',
  MASKED_TYPE = 'maskedType',
  CUSTOM_OPTION = 'customOption',
  DROPDOWN = 'dropdown',
}
